module.exports = {
  'main.browser.description':
    'Digital Design & Development is a 3-year English or Flemish taught bachelor degree and is part of The Royal Academy of Fine Arts (KASK) and the Royal Conservatory.',
  'main.browser.keywords':
    'Development, Design, Experience Design, Research, KASK, Howest, Kortrijk, Digital, Bachelor',
  'main.moreinfo.title': 'Does the future look Devine?',
  'main.moreinfo.title2': 'Would you like to know more?',
  'main.moreinfo.p':
    "If you have any questions, don't hesitate to contact us and we will personally inform you on anything you want or need to know before you become a Deviner.",
  'main.moreinfo.subtitle': '',
  'main.moreinfo.subtitle2': '',
  'main.moreinfo.button1': 'How to apply?',
  'main.moreinfo.button2': 'Get in touch',
  'main.moreinfo.buttonboot': '',
  'main.footer.voorwaarden': 'General Terms & Conditions',
  'main.footer.acyear': 'Academic year 2023-2024',
  'main.consent.p':
    'This website uses cookies to improve your user experience.',
  'main.consent.meer': 'More info',
  'main.consent.accept': 'Accept Cookies',
  'main.social.youtube': 'Youtube: view Devine in motion(graphics) ',
  'main.social.facebook': 'Facebook: find our upcoming events and articles',
  'main.social.instagram': 'Instagram: see us alive and kicking',
  'main.social.github': 'Dive into our code on Github',
  'main.social.linkedin': 'Devine on Linkedin',
  'main.social.behance': 'Student work on Behance',
  'main.social.twitter': 'Devine tweets',
  'main.social.email': 'Devine Email',
  'main.menu.practical': 'practical',
  'main.studentlife.p1': 'Travel',
  'main.studentlife.p2': 'Lectures',
  'main.studentlife.p3': 'Internships',
  'main.studentlife.p4': 'Competitions',
  'main.studentlife.subtitle': 'Always on the move',
  'main.studentlife.p6':
    'First year students explore Rotterdam, Brussel or Lille to get inspired by local expositions. In your 2nd year we broaden our horizons and go even further on study trips to Berlin, San Francisco, Dublin, ... visiting expositions and installations that pop up all over the world that showcase the next generation in creativity, design, innovation, food art and business. The line up of chosen events and expositions are hand picked by a team of lectors to inspire you. We also visit top companies such as Google, Leap, Oculus, Adobe, Facebook, AirBNB,...    As your skills advance, you have the opportunity to compete with other schools to present the most creative and innovative solution during several hackathons and competitions. Whatever you decide to do, you can be sure that Devine will become an unforgetable journey.',
  'main.studentlife.p7':
    'During your integration project in your second year, you briefly join an international student exchange program with the University of Rotterdam in the Netherlands. For a whole week, you are their honored guest, working on a project with the students of Cross Media Design. In the final week of the project, they join us on our campus to finish the project with working prototypes.',
  'main.studentlife.p8':
    'Proactively explore the creative sector by joining organised company visits. First, second and third year students witness first hand how real-life needs are met in the industry. Or visit one of the many interesting conferences and be inspired by talks from professionals in our field.  ',
  'main.studentlife.subtitle2': 'Become an expert in your field',
  'main.studentlife.p9':
    'All the workshops, lectures, talks, competitions and challenges you have faced during your 3 year program have prepped you to hit the ground running when you graduate. This is enforced by the numerous meet and greet opportunities we organise with experts from the field. They lead the way, give you advice and teach you the ropes on designing and developing in the real world. These guest speakers are piece by piece well known and respected members of the creative industry and provide invaluable insights.  ',
  'main.studentlife.p10':
    'On the flip side, you push the boundaries of the institution by proactively looking for the next best thing. These creative meet-ups, webinars, conferences, talks, events are all part of your education and you understand that a life long learning mentality is the best state of mind to excel as an expert. Discover a wide range of extra curricular events via the school partners: <budalab>Budalab</budalab>, <designregio>Design Regio Kortrijk</designregio>, Voka, <hangark>Hangar K</hangark>, <flandersdc>Flanders DC</flandersdc>,...',
  'main.studentlife.p11':
    'To know more about these events, check out our social media.',
  'main.bump.title': 'Bump Festival',
  'main.bump.p1':
    'Every Devine student (and educator) looks forward to the end of the year festivities with our very own Bump festival. International speakers ranging from graphic designers to creative coders and motion graphic artists are invited to share their key learnings and inspiration during Bump. Previous speakers include Annie Atkins, Neil Mendoza, Kate Dawkins, Hayley Hughes, Ash thorp, Sougwen Chung... It is a celebration of industry knowledge and we end this one day festival in style with music and drinks. See you there?   ',
  'main.bump.button': 'go to the Bump website',
  'main.bump.p2':
    'Subscribe to our <nieuwsbrief>Bump Festival newsletter </nieuwsbrief> and stay up to date on the next Bump edition.',
  'main.form.errorvoorwaarden': 'Please agree with the terms and conditions.',
  'main.form.errorfamilienaam': 'Please complete this required field.',
  'main.form.errornaam': 'Please complete this required field.',
  'main.form.erroremail': 'please provide a valid email address',
  'main.form.naam': 'Name',
  'main.form.familienaam': 'Surname',
  'main.form.email': 'Email address',
  'main.form.voorwaarden':
    'I agree to the <voorwaarden>terms and conditions</voorwaarden>',
}
